<template>
  <Button
    v-if="enabledFeaturesStore.isFeatureEnabled('subscriptions')"
    data-test-id="open-subscription-button"
    color="transparentOutlined"
    class="w-full"
  >
    <DynamicPictogram name="subscriptions" class="mr-2" size="ml" />
    {{
      $t('subscriptions.subscribe_and_save', {
        percentage: subscriptionDiscountPercentage,
      })
    }}
  </Button>
</template>

<script setup lang="ts">
import { useSubscriptionStore } from '~/stores/subscription';
import { useEnabledFeaturesStore } from '~/stores/enabledFeatures';

const enabledFeaturesStore = useEnabledFeaturesStore();
const subscriptionStore = useSubscriptionStore();

const { subscriptionDiscountPercentage } = subscriptionStore;
</script>
