<template>
  <TabAccordion :list="sanityData?.description">
    <template #summary="{ item }">
      {{ item.tabName }}
    </template>
    <template #detail="{ item }">
      <ProductOverview v-if="item._type === 'productOverview'" :block="item" />
      <Nutrition v-else-if="item._type === 'nutrition'" :block="item" />
      <KeyBenefits v-else-if="item._type === 'keyBenefits'" :block="item" />
      <Ingredients v-else-if="item._type === 'ingredients'" :block="item" />
      <FeedingGuide v-else-if="item._type === 'feedingGuide'" :block="item" />
      <FrequentlyAskedQuestions
        v-else-if="item._type === 'frequentlyAskedQuestions'"
        :block="item"
      />
    </template>
  </TabAccordion>
</template>

<script setup lang="ts">
import type { product as sanityProduct } from '#root/cms/sanity.types';

defineProps({
  sanityData: {
    type: Object as () => sanityProduct /* Sanity */,
    required: false,
    default: () => ({}),
  },
});

const ProductOverview = defineAsyncComponent(
  () => import('./tabs/ProductOverview.vue')
);

const Nutrition = defineAsyncComponent(() => import('./tabs/Nutrition.vue'));

const KeyBenefits = defineAsyncComponent(
  () => import('./tabs/KeyBenefits.vue')
);

const Ingredients = defineAsyncComponent(
  () => import('./tabs/Ingredients.vue')
);

const FeedingGuide = defineAsyncComponent(
  () => import('./tabs/FeedingGuide.vue')
);

const FrequentlyAskedQuestions = defineAsyncComponent(
  () => import('./tabs/FrequentlyAskedQuestions.vue')
);
</script>

<style scoped>
.details-tab::-webkit-details-marker {
  display: none;
}
</style>
