<template>
  <div class="overflow-y-auto px-5 pb-10 pt-1 md:h-full md:py-5">
    <h2 class="mb-4 text-center text-2xl text-supporting-black-87">
      {{ $t('subscriptions.why_subscription_modal.title') }}
    </h2>
    <ul class="mb-4">
      <li class="mb-6 flex text-supporting-black-54">
        <StaticPictogram
          name="schedule"
          size="2xl"
          class="my-auto mr-4 shrink-0"
        />
        <span>{{
          rt($tm('subscriptions.why_subscription_modal.arguments')[0])
        }}</span>
      </li>
      <li class="mb-6 flex text-supporting-black-54">
        <StaticPictogram name="tag" size="2xl" class="my-auto mr-4 shrink-0" />
        <span>{{
          rt($tm('subscriptions.why_subscription_modal.arguments')[1])
        }}</span>
      </li>

      <li class="mb-6 flex text-supporting-black-54">
        <StaticPictogram name="pen" size="2xl" class="my-auto mr-4 shrink-0" />
        <span>{{
          rt($tm('subscriptions.why_subscription_modal.arguments')[2])
        }}</span>
      </li>
    </ul>
    <Button
      class="my-2 w-full min-w-40"
      @click="emit('update:isOpen', false)"
      >{{ $t('subscriptions.why_subscription_modal.submit_button') }}</Button
    >
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits(['update:isOpen']);

const { rt } = useI18n();
</script>
