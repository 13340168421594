<template>
  <ClearCacheMeta v-if="relatedProductsError" />
  <div class="mx-auto mt-4 flex max-w-6xl flex-col md:mt-6 md:px-6">
    <div v-if="relatedProductsResult && relatedProductsResult.length">
      <h3 class="mb-4 px-4 text-2xl md:ml-4 md:px-0">
        {{ $t('product.related_products') }}
      </h3>

      <ProductsSwiper
        v-if="relatedProductsResult.length > 0"
        :products="relatedProductsResult"
      >
        <template #product="{ product: relatedProduct, index }">
          <ProductCard
            :id="parseInt(relatedProduct.id)"
            :key="parseInt(relatedProduct.id)"
            class="overflow-hidden"
            :slug="removeSlashes(relatedProduct.path)"
            :title="relatedProduct.title"
            :regular-price="relatedProduct.base_price"
            :special-price="relatedProduct.sale_price || ''"
            :is-cheapest-variant-price="
              relatedProduct.is_cheapest_variant_price
            "
            :image="relatedProduct.image || relatedProduct.parentImage"
            :brand="{
              entityId: +relatedProduct.brandId,
              name: relatedProduct.brandName,
              slug: removeSlashes(relatedProduct.brandPath),
            }"
            :category-ids="relatedProduct.categoriesId.map((id) => +id)"
            :tracking-data="{
              product: relatedProduct.trackingData,
              position: index + 1,
              extraFields: { brand: relatedProduct.brandName },
              list: `Related product - ${relatedProduct.name}`,
            }"
            :options="relatedProduct.options"
            :variants="relatedProduct.variants"
            :campaign="relatedProduct.campaign"
            :is-available="relatedProduct.is_available"
            :product-click-callback="
              () => sendLoop54ClickEvent(relatedProduct.id)
            "
            :add-to-cart-callback="
              () => sendLoop54AddToCartEvent(relatedProduct.id)
            "
            :is-vet-recommended="relatedProduct.is_vet_recommended"
          />
        </template>
      </ProductsSwiper>
    </div>
    <Grid
      v-if="relatedProductsPending"
      class="mb-16 overflow-hidden"
      style="height: 365px"
    >
      <ProductSkeleton v-for="n in 4" :key="n" />
    </Grid>
  </div>
</template>
<script setup lang="ts">
import { useSearchStore } from '~/stores/search';
import { removeSlashes } from '#root/shared/utils/types/string-utils';

const loop54UserId: CookieRef<string | undefined | null> =
  useLoop54UserIdCookie();

const searchStore = useSearchStore();

const props = defineProps<{
  productId: number;
}>();

const productId = computed(() => props?.productId);

const sendLoop54ClickEvent = (id: number) => {
  searchStore.sendLoop54ClickEvent(id);
};
const sendLoop54AddToCartEvent = (id: number) => {
  searchStore.sendLoop54AddToCartEvent(id);
};

// Fetch related products
const {
  data: relatedProductsResult,
  pending: relatedProductsPending,
  execute: relatedProductsExecute,
  error: relatedProductsError,
} = await useApiFetch<any[]>(`/rest/search/related`, {
  lazy: true,
  method: 'post',
  body: {
    id: productId.value,
    take: 8,
  },
  headers: {
    'User-Id': process.client && loop54UserId.value ? loop54UserId.value : '', // We don't want to pass User-Id on server to avoid caching personalized results
  },
  immediate: process.server, // We should have product ID on server, therefore we can fetch it immediately server side
  watch: false,
});

// Create a computed property for productFetchStatus

watch(
  productId, // Use computed property here
  (newProductId) => {
    if (newProductId !== undefined) {
      if (process.client) {
        relatedProductsExecute();
      }
    }
  },
  { immediate: true }
);
</script>
