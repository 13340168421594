<template>
  <div v-if="simplified">
    <span v-if="showDeliveryInfo || !props.inStock" class="text-black/54">{{
      deliveryText
    }}</span>
  </div>
  <div v-else class="flex max-w-full items-center md:gap-3">
    <div
      v-if="props.inStock"
      class="flex max-w-full flex-1 items-center gap-3 text-black/54 md:flex-none"
    >
      <StaticPictogram :name="stockIcon" size="lg" class="min-w-6" />
      <span
        >{{ stockText }}.
        <template v-if="showDeliveryInfo">{{ deliveryText }}</template></span
      >
    </div>
    <span v-if="!props.inStock">{{ deliveryText }}</span>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();

const props = defineProps({
  stockCount: {
    type: Number,
    default: 0,
  },
  inStock: {
    type: Boolean,
    default: false,
  },
  showDeliveryInfo: {
    type: Boolean,
    default: true,
  },
  simplified: {
    type: Boolean,
    default: false,
  },
});

const stockIcon = computed(() => {
  if (props.stockCount >= 3) {
    return 'in-stock';
  }
  if (props.stockCount > 0) {
    return 'low-in-stock';
  }
  return 'back-order';
});

const stockText = computed(() => {
  if (props.stockCount >= 3) {
    return t('product.in_stock');
  }
  if (props.stockCount > 0) {
    return t('product.low_stock');
  }
  return t('product.back_order');
});

const deliveryText = computed(() => {
  if (!props.inStock) {
    return t('product.out_of_stock_desc');
  }
  if (props.stockCount > 0) {
    return t('product.delivery_in_stock');
  }
  return t('product.delivery_back_order');
});
</script>
