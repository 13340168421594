<template>
  <div v-if="images.length === 1 && !showModal" class="w-full">
    <ProductDetailImage
      :image="images[0]"
      :is-recommended="isRecommended"
      cursor="cursor-pointer"
      @click="toggleModal"
    />
  </div>

  <Modal
    v-else-if="images.length === 1 && showModal"
    :full-screen-modal="true"
    :is-visible="true"
    @close="toggleModal"
    ><div class="overflow-hidden pb-2 pt-6">
      <ProductDetailImage
        :image="images[0]"
        :is-recommended="isRecommended"
        cursor="cursor-pointer"
        :has-fixed-dimensions="false"
        @click="toggleModal"
      />
    </div>
  </Modal>

  <Modal
    v-else-if="showModal"
    :full-screen-modal="true"
    :is-visible="true"
    @close="toggleModal"
    ><div class="overflow-hidden pb-2 pt-6">
      <ProductDetailImagesSwiper
        :images="images"
        :sku="sku"
        :is-recommended="isRecommended"
        :show-thumbnails="false"
        :has-fixed-dimensions="false"
        :active-group="activeGroup"
        :goto-active-group="gotToActiveGroup"
        @toggle-modal="toggleModal"
        @click="toggleModal"
        @group-changed="handleGroupChange"
      />
    </div>
  </Modal>
  <div v-else>
    <ProductDetailImagesSwiper
      :images="images"
      :sku="sku"
      :is-recommended="isRecommended"
      :active-group="activeGroup"
      :goto-active-group="gotToActiveGroup"
      @toggle-modal="toggleModal"
      @group-changed="handleGroupChange"
    />
  </div>
</template>
<script setup lang="ts">
const showModal = ref(false);

const toggleModal = () => {
  showModal.value = !showModal.value;
};

interface Props {
  images: { id: string; src: string; alt: string }[];
  sku: string;
  isRecommended: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
  sku: '',
  isRecommended: false,
});

const activeGroup = ref(0);

const handleGroupChange = (newActiveGroup: number) => {
  activeGroup.value = newActiveGroup;
};

const gotToActiveGroup = computed(
  () => props.images.findIndex((image) => image.id?.includes(props.sku)) + 1
);
</script>
