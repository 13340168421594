<template>
  <template
    v-for="(item, index) in sanityData?.productPageBuilder"
    :key="index"
  >
    <ProductTestimonials
      v-if="item._type === 'productTestimonials'"
      :block="item"
    />
    <ProductTextWithImage
      v-else-if="item._type === 'productTextWithImage'"
      :block="item"
    />
    <HowToTransition
      v-else-if="item._type === 'howToTransition'"
      :block="item"
    />
    <ProductBrandSwiper
      v-else-if="item._type === 'productBrandSwiper'"
      :block="item"
    />
  </template>
</template>

<script setup lang="ts">
import type { product as sanityProduct } from '#root/cms/sanity.types';

defineProps({
  sanityData: {
    type: Object as () => sanityProduct /* Sanity */,
    required: false,
    default: () => ({}),
  },
});

const ProductTestimonials = defineAsyncComponent(
  () => import('./blocks/productTestimonials/ProductTestimonials.vue')
);

const ProductTextWithImage = defineAsyncComponent(
  () => import('./blocks/productTextWithImage/ProductTextWithImage.vue')
);

const HowToTransition = defineAsyncComponent(
  () => import('./blocks/HowToTransition.vue')
);

const ProductBrandSwiper = defineAsyncComponent(
  () => import('./blocks/ProductBrandSwiper.vue')
);
</script>
