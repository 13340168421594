import { ref, onMounted, onUnmounted } from 'vue';

// Define Tailwind breakpoints
const breakpoints = {
  sm: '(min-width: 640px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
  '2xl': '(min-width: 1536px)',
};

export function useTailwindBreakpoints() {
  // Create reactive references for each breakpoint
  const isSm = ref(false);
  const isMd = ref(false);
  const isLg = ref(false);
  const isXl = ref(false);
  const is2xl = ref(false);

  // Helper function to create and update the media query listeners
  const updateBreakpointState = (mediaQuery: MediaQueryList, refState: any) => {
    // eslint-disable-next-line no-param-reassign
    refState.value = mediaQuery.matches;
  };

  // MediaQueryList objects for each breakpoint
  let smQuery: MediaQueryList;
  let mdQuery: MediaQueryList;
  let lgQuery: MediaQueryList;
  let xlQuery: MediaQueryList;
  let xxlQuery: MediaQueryList;

  // Check if we are in a client environment
  if (process.client) {
    smQuery = window.matchMedia(breakpoints.sm);
    mdQuery = window.matchMedia(breakpoints.md);
    lgQuery = window.matchMedia(breakpoints.lg);
    xlQuery = window.matchMedia(breakpoints.xl);
    xxlQuery = window.matchMedia(breakpoints['2xl']);

    // Update the state initially
    updateBreakpointState(smQuery, isSm);
    updateBreakpointState(mdQuery, isMd);
    updateBreakpointState(lgQuery, isLg);
    updateBreakpointState(xlQuery, isXl);
    updateBreakpointState(xxlQuery, is2xl);

    // Event listeners for each breakpoint
    const handleSmChange = (e: MediaQueryListEvent) =>
      updateBreakpointState(e, isSm);
    const handleMdChange = (e: MediaQueryListEvent) =>
      updateBreakpointState(e, isMd);
    const handleLgChange = (e: MediaQueryListEvent) =>
      updateBreakpointState(e, isLg);
    const handleXlChange = (e: MediaQueryListEvent) =>
      updateBreakpointState(e, isXl);
    const handle2xlChange = (e: MediaQueryListEvent) =>
      updateBreakpointState(e, is2xl);

    onMounted(() => {
      // Add event listeners
      smQuery.addEventListener('change', handleSmChange);
      mdQuery.addEventListener('change', handleMdChange);
      lgQuery.addEventListener('change', handleLgChange);
      xlQuery.addEventListener('change', handleXlChange);
      xxlQuery.addEventListener('change', handle2xlChange);
    });

    onUnmounted(() => {
      // Remove event listeners
      smQuery.removeEventListener('change', handleSmChange);
      mdQuery.removeEventListener('change', handleMdChange);
      lgQuery.removeEventListener('change', handleLgChange);
      xlQuery.removeEventListener('change', handleXlChange);
      xxlQuery.removeEventListener('change', handle2xlChange);
    });
  }

  // Return the reactive states
  return {
    isSm,
    isMd,
    isLg,
    isXl,
    is2xl,
  };
}
