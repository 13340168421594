<template>
  <div
    v-if="filteredList.length"
    class="min-[1480px]:max-w-[calc(100% + 13rem)] min-[1430px]:max-w-[calc(100% + 10rem)] my-7 -mr-0 flex flex-col rounded-xl border border-black/26 p-3 text-base md:w-fit md:text-sm min-[1430px]:my-0 min-[1430px]:mt-3 min-[1430px]:flex-row min-[1430px]:space-x-4"
    :class="{
      'min-[1430px]:-mr-40 min-[1480px]:-mr-52': !isFrozenFood,
    }"
  >
    <StockLevel
      v-if="
        enabledFeaturesStore.isFeatureEnabled('stockLevel') &&
        !isVariantSelected
      "
      :stock-count="stockCount"
      :in-stock="inStock"
      :show-delivery-info="!isFrozenFood"
      class="flex max-w-full py-3 min-[1430px]:py-0"
    />
    <div
      v-for="item in filteredList"
      :key="item.icon"
      class="flex items-center py-3 min-[1430px]:py-0"
    >
      <StaticPictogram
        class="mr-3 min-[1430px]:mr-2"
        size="lg"
        :name="item.icon"
      />
      <span>{{ item.text }}</span>

      <img
        v-if="item.logo"
        class="ml-2 h-[24px] w-auto"
        loading="lazy"
        :src="item.logo"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const { t, locale } = useI18n();
const enabledFeaturesStore = useEnabledFeaturesStore();

const props = defineProps({
  isFrozenFood: {
    type: Boolean,
    default: false,
  },
  stockCount: {
    type: Number,
    default: 0,
  },
  inStock: {
    type: Boolean,
    default: false,
  },
  isVariantSelected: {
    type: Boolean,
    default: false,
  },
});

const { freeFromAmount } = useShopCountryConfig().shippingRates;

const infoItems = ref([
  {
    icon: 'pick-up',
    text: t('product.product_info.free_delivery_at_pick_up_point'),
    regular: true,
    frozen: false,
    market: [], // Currently not displayed in any market as we have removed free pick up point delivery
  },
  {
    icon: 'frozen',
    text: t('product.product_info.home_delivery_for_frozen_goods'),
    regular: false,
    frozen: true,
    market: ['sv'],
  },
  {
    icon: 'home',
    text: t('product.product_info.free_home_delivery_over', {
      amount: freeFromAmount,
    }),
    regular: true,
    frozen: false,
    market: ['sv', 'uk'],
  },
  {
    icon: 'returns',
    text: t('product.product_info.free_returns'),
    regular: true,
    frozen: false,
    market: ['sv', 'uk'],
  },
]);

const filteredList = computed(() =>
  infoItems.value.filter((item) => {
    if (!item.market.includes(locale.value)) {
      return false;
    }
    if (props.isFrozenFood) {
      return item.frozen;
    }
    return item.regular;
  })
);
</script>
