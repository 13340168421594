<template>
  <!-- See SubscriptionSetupDialog.client.vue
    93dvh is the height of the modal
    76px is the top margin of the modal content
  -->
  <div class="my-4 h-[calc(93dvh-76px)] overflow-y-auto px-6 pb-4 md:h-full">
    <h2
      class="hidden text-center text-2xl text-supporting-black-87 md:mt-6 md:block"
    >
      {{
        $t('subscriptions.subscribe_and_save', {
          percentage: subscriptionDiscountPercentage,
        })
      }}
    </h2>
    <p class="mt-1 text-center text-supporting-black-54">
      {{ $t('subscriptions.add_subscription_modal.description') }}
      <a
        class="cursor-pointer text-primary-default"
        @click="emit('open-why-subscription')"
        >{{ $t('subscriptions.add_subscription_modal.why_subscribe') }}</a
      >
    </p>

    <h3 class="mb-1 mt-6 flex font-sans font-semibold text-supporting-black-87">
      {{ $t('subscriptions.add_subscription_modal.variant_label') }}
    </h3>
    <SelectLite
      v-if="sizes.length > 0"
      v-model="selectedSize"
      :options="sizes"
      :special-label="$t('subscriptions.add_subscription_modal.free_shipping')"
      :special-label-condition="hasFreeShipping"
      :selected-index="selectedVariantIndex"
      special-label-color="text-supporting-dark-4"
      class="min-w-[240px]"
      option-class="md:fixed md:w-[calc(100%-theme(space.12))]"
      data-test-id="subscription-size-select"
    />
    <h3 class="mb-1 mt-6 flex font-sans font-semibold text-supporting-black-87">
      {{ $t('subscriptions.add_subscription_modal.quantity_label') }}
    </h3>
    <QuantitySelector
      :show-cart-pictogram="false"
      size="sm"
      :debounce-ms="0"
      :min-quantity="1"
      :qty="quantity"
      @input="quantityChange"
    />

    <h3 class="mb-1 mt-8 flex font-sans font-semibold text-supporting-black-87">
      {{ $t('subscriptions.add_subscription_modal.frequency_label') }}
    </h3>
    <SelectLite
      v-if="frequencies.length > 0"
      v-model="selectedFrequencyInDays"
      :options="frequencies"
      class="min-w-[240px]"
      option-class="md:fixed md:w-[calc(100%-theme(space.12))]"
      data-test-id="subscription-frequency-select"
    />

    <div class="mt-8 font-title text-2xl md:text-3xl">
      <span
        class="mr-1"
        :class="discountedVariantPrice ? 'text-sale-price' : 'text-price'"
      >
        {{ $formatPrice(discountedVariantPrice) }}{{ ' ' }}
      </span>
      <span
        v-if="selectedVariantBasePrice"
        class="inline-block text-base-price line-through"
      >
        {{ $formatPrice(selectedVariantBasePrice) }}
      </span>
    </div>
    <div class="mb-8 text-supporting-black-54">
      <SubscriptionFrequencyLabel
        :frequency-in-days="selectedFrequencyInDays"
      />
    </div>
    <p
      v-if="alreadyAddedAsSinglePurchase"
      class="mb-3 flex items-center text-supporting-dark-2"
    >
      <DynamicPictogram name="warning" class="mr-3" size="lg" />
      {{
        $t(
          'subscriptions.add_subscription_modal.replace_single_purchase_warning'
        )
      }}
    </p>
    <Button
      class="w-full min-w-40"
      data-test-id="add-subscription-button"
      @click="addSubscriptionProduct"
      >{{
        $t('subscriptions.add_subscription_modal.add_subscription_to_cart')
      }}</Button
    >
    <div
      v-if="
        enabledFeaturesStore.isFeatureEnabled('stockLevel') && selectedVariant
      "
      class="mt-4 text-center text-supporting-black-54"
    >
      <StockLevel
        :stock-count="selectedVariant.stock_count"
        :in-stock="selectedVariant.is_purchasable"
        :show-delivery-info="showDeliveryInfo"
        :simplified="true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSubscriptionStore } from '~/stores/subscription';
import type { CartSubscriptionItem } from '#root/shared/types/subscription-types';
import { imageAltTextIncludesSku } from '#root/shared/utils/product-utils';

const localePath = useLocalePath();
const { t } = useI18n();
const { shippingRates } = useShopCountryConfig();

const enabledFeaturesStore = useEnabledFeaturesStore();
const subscriptionStore = useSubscriptionStore();
const cartStore = useCartStore();

const {
  subscriptionDiscountPercentage,
  addSubscriptionProduct: addSubscriptionProductToStore,
} = subscriptionStore;

const props = defineProps({
  variants: {
    type: Object,
    default: () => {},
    required: true,
  },
  product: {
    type: Object,
    default: () => {},
  },
  showDeliveryInfo: {
    type: Boolean,
    default: false,
  },
  selectedVariantId: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([
  'open-why-subscription',
  'added-subscription-product',
]);

const selectedVariantIndex = props.variants.findIndex(
  (variant: { id: number }) => variant.id === props.selectedVariantId
);

const sizes = computed(() => {
  if (!props.variants || props.variants.length === 0) return [];

  return props.variants
    .filter((v) => v.is_purchasable)
    .map((v) => ({
      label: v.option_values[0].label, // NOTE: this only supports variants with only one option, which is the case for food products and subscriptions
      value: v.id,
    }));
});

// The _two variant exists because the pluralization form for every 2 weeks is different from subsequent numbers in Swdish
// Ticket ECOM-1037 offers a proposal for a more complex reusable solution
const frequencies = Array.from({ length: 11 }, (_, i) => i + 2).map((freq) => ({
  label:
    freq === 2
      ? t('subscriptions.frequency_label.every_other_week')
      : t('subscriptions.frequency_label.every_x_weeks', { weeks: freq }),
  value: freq * 7,
}));

const extendedVariants = computed(() => ({
  ...props.variants,
  values: props.variants?.map((v) => {
    const basePrice = v.prices.basePrice.value ?? 0;

    const discountedPrice =
      basePrice - basePrice * (subscriptionDiscountPercentage / 100);

    const hasFreeShipping = shippingRates.freeFromAmount - discountedPrice <= 0;

    return { ...v, basePrice, discountedPrice, hasFreeShipping };
  }),
}));

const selectedSize = ref();
const quantity = ref({ value: 1 });
const selectedFrequencyInDays = ref(0);

const selectedVariant = computed(() =>
  extendedVariants.value?.values.find((v) => v.id === selectedSize.value)
);

const selectedVariantBasePrice = computed(
  () =>
    (selectedVariant?.value?.prices.basePrice.value ?? 0) * quantity.value.value
);

const selectedVariantPrice = computed(
  () => selectedVariant?.value?.prices.price?.value ?? 0
);

const discountedVariantPrice = computed(() => {
  if (!selectedVariantPrice || !subscriptionDiscountPercentage) return 0;
  return (
    (selectedVariantPrice.value -
      selectedVariantPrice.value * (subscriptionDiscountPercentage / 100)) *
    quantity.value.value
  );
});

const productImages = computed(() => props.product?.images?.edges);

const variantImages = computed(() =>
  productImages.value?.filter((imageEdge: any) =>
    imageAltTextIncludesSku(
      imageEdge?.node?.altText,
      selectedVariant.value?.sku
    )
  )
);

const alreadyAddedAsSinglePurchase = computed(() =>
  cartStore.getItemInCart(props.product.entityId, selectedSize.value)
);

const quantityChange = (newQuantity: number) => {
  quantity.value.value = newQuantity;
};

const addSubscriptionProduct = async () => {
  if (alreadyAddedAsSinglePurchase.value?.id) {
    await cartStore.deleteCartItem(alreadyAddedAsSinglePurchase.value.id);
  }

  const subscriptionProduct: Omit<CartSubscriptionItem, 'prices'> = {
    quantity: quantity.value.value,
    productId: props.product.entityId,
    variantId: selectedSize.value,
    frequencyInDays: selectedFrequencyInDays.value,
    // Temporarily used for testing
    name: props.product.name,
    path: localePath({
      name: 'shop-product-slug',
      params: { slug: props.product.slug },
    }),
    defaultImageUrl:
      variantImages.value?.[0]?.node?.url || props.product.defaultImage?.url,
    unitPrices: selectedVariant?.value.prices,
    brand: props.product.brand,
    categories: props.product.categories.edges,
    animalType: props.product.animalCategories[0].node.animal,
    options: [
      {
        nameId: selectedVariant.value.id, // NOTE: this only supports variants with only one option, which is the case for food products and subscriptions
        value: selectedVariant.value.option_values[0].label,
      },
    ],
    campaign: props.product.campaign,
  };

  addSubscriptionProductToStore(subscriptionProduct);

  emit('added-subscription-product', selectedFrequencyInDays.value);

  // TODO: Move to the next modal
};

const hasFreeShipping = (variantId: string | number) =>
  extendedVariants.value.values.find((ev) => ev.id === variantId)
    ?.hasFreeShipping;
</script>
