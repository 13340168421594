<template>
  <div
    v-if="
      sanityData?.badge === 'vetBadgeSensitive' ||
      sanityData?.badge === 'vetBadgeOriginal'
    "
    class="mb-6 mt-5 flex justify-center gap-2 px-4 md:mb-10 md:gap-4"
  >
    <div
      v-for="(perk, index) in perks"
      :key="index"
      class="flex w-1/4 max-w-40 flex-col items-center gap-3 text-center text-[13px] font-semibold leading-normal text-supporting-black-87 antialiased md:text-base md:font-normal"
    >
      <img
        class="z-10 size-14 rounded-full md:size-20"
        :class="backgroundColor"
        width="80"
        height="80"
        :src="perk.icon"
      />
      {{ perk.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { product as sanityProduct } from '#root/cms/sanity.types'; /* Sanity */
import perkIconDog from '@/assets/images/product/perk-icon-dog.svg';
import perkIconVet from '@/assets/images/product/perk-icon-vet.svg';
import perkIconScience from '@/assets/images/product/perk-icon-science.svg';
import perkIconMoneyBack from '@/assets/images/product/perk-icon-money-back.svg';

const props = defineProps({
  sanityData: {
    type: Object as () => sanityProduct /* Sanity */,
    required: false,
    default: () => ({}),
  },
});

const perks = computed(() =>
  props.sanityData?.badge === 'vetBadgeSensitive'
    ? perksSensitive
    : perksOriginal
);

const backgroundColor = computed(() =>
  props.sanityData?.badge === 'vetBadgeSensitive'
    ? 'bg-[#dce3f7]'
    : 'bg-[#D6E0C3]'
);

const perksOriginal = [
  { icon: perkIconDog, text: 'For all adult dog breeds' },
  { icon: perkIconMoneyBack, text: 'Money-back taste guarantee' },
  { icon: perkIconVet, text: 'Developed by Swedish vets' },
  { icon: perkIconScience, text: 'Backed by science' },
];

const perksSensitive = [
  { icon: perkIconDog, text: 'For all adult dogs with sensitive stomachs' },
  { icon: perkIconMoneyBack, text: 'Money-back taste guarantee' },
  { icon: perkIconVet, text: 'Developed by Swedish vets' },
  { icon: perkIconScience, text: 'Backed by science' },
];
</script>
