import { LocationQuery } from '#vue-router';

/**
 * Determine if variant is selected based on selected options.
 */
const isVariantSelected = (selectedOptions: any, variant: any) =>
  Object.keys(selectedOptions).every((selectedOptionId) =>
    variant.option_values.find(
      (optionValue: any) =>
        optionValue.option_id === +selectedOptionId &&
        optionValue.id === +selectedOptions[selectedOptionId]
    )
  );

/**
 * Get variant from selected options.
 */
const getSelectedVariant = (selectedOptions: any, variants: any[]) => {
  if (variants?.length === 1) {
    return variants?.length ? variants[0] : null;
  }

  if (Object.values(selectedOptions).length) {
    return variants.find((variant) =>
      isVariantSelected(selectedOptions, variant)
    );
  }

  return null;
};

/**
 * Parse query params 'options' and 'values' on product page.
 */
const formatQueryParams = (
  query: LocationQuery
): { [optionId: string]: number } => {
  const { options, values } = query;
  const queryOptions = options ? `${options}` : null;
  const queryValues = values ? `${values}` : null;

  return queryOptions && queryValues
    ? queryOptions.split(',').reduce(
        (acc, option, index) => ({
          ...acc,
          [option]: +queryValues.split(',')[index],
        }),
        {}
      )
    : {};
};

export { isVariantSelected, getSelectedVariant, formatQueryParams };
