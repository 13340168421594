<template>
  <div class="mx-auto mt-4 flex max-w-6xl flex-col md:mt-6 md:px-6">
    <div
      v-if="complementaryProductsResult && complementaryProductsResult.length"
    >
      <h3 class="mb-4 mt-3 px-4 text-2xl md:ml-4 md:mt-0 md:px-0">
        {{ $t('product.recommended_for_you') }}
      </h3>

      <ProductsSwiper
        v-if="complementaryProductsResult.length > 0"
        :products="complementaryProductsResult"
      >
        <template #product="{ product: complementaryProduct, index }">
          <ProductCard
            :id="parseInt(complementaryProduct.id)"
            :key="parseInt(complementaryProduct.id)"
            class="overflow-hidden"
            :slug="removeSlashes(complementaryProduct.path)"
            :title="complementaryProduct.title"
            :regular-price="complementaryProduct.base_price"
            :special-price="complementaryProduct.sale_price || ''"
            :is-cheapest-variant-price="
              complementaryProduct.is_cheapest_variant_price
            "
            :image="
              complementaryProduct.image || complementaryProduct.parentImage
            "
            :brand="{
              entityId: complementaryProduct.brandId,
              name: complementaryProduct.brandName,
              slug: removeSlashes(complementaryProduct.brandPath),
            }"
            :category-ids="
              complementaryProduct.categoriesId.map((id: string) => +id)
            "
            :tracking-data="{
              product: complementaryProduct.trackingData,
              position: index + 1,
              extraFields: { brand: complementaryProduct.brandName },
              list: `Complementary product - ${complementaryProduct.name}`,
            }"
            :options="complementaryProduct.options"
            :variants="complementaryProduct.variants"
            :campaign="complementaryProduct.campaign"
            :is-available="complementaryProduct.is_available"
            :product-click-callback="
              () => sendLoop54ClickEvent(complementaryProduct.id)
            "
            :add-to-cart-callback="
              () => sendLoop54AddToCartEvent(complementaryProduct.id)
            "
          />
        </template>
      </ProductsSwiper>
    </div>
    <Grid
      v-if="complementaryProductsPending"
      class="mb-16 overflow-hidden"
      style="height: 365px"
    >
      <ProductSkeleton v-for="n in 4" :key="n" />
    </Grid>
  </div>
</template>
<script setup lang="ts">
import { useSearchStore } from '~/stores/search';
import { removeSlashes } from '#root/shared/utils/types/string-utils';

const loop54UserId: CookieRef<string | undefined | null> =
  useLoop54UserIdCookie();

const searchStore = useSearchStore();

const props = defineProps<{
  productId: number;
}>();

const productId = computed(() => props?.productId);

const sendLoop54ClickEvent = (id: number) => {
  searchStore.sendLoop54ClickEvent(id);
};
const sendLoop54AddToCartEvent = (id: number) => {
  searchStore.sendLoop54AddToCartEvent(id);
};

const {
  data: complementaryProductsResult,
  pending: complementaryProductsPending,
  execute: complementaryProductsExecute,
} = await useApiFetch<any[]>('/rest/search/complementary', {
  lazy: true,
  method: 'post',
  body: {
    id: productId.value,
    take: 9,
  },
  headers: { 'User-Id': loop54UserId.value || '' },
  immediate: false, // Should only be fetched client side since they are personalized
  watch: false,
});

// Create a computed property for productFetchStatus

watch(
  productId, // Use computed property here
  (newProductId) => {
    if (newProductId !== undefined) {
      if (process.client) {
        complementaryProductsExecute();
      }
    }
  },
  { immediate: true }
);
</script>
