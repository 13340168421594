<template>
  <SwipeModal
    v-if="!mdAndLarger"
    v-model="model"
    snap-point="auto"
    :is-scroll-lock="true"
    :is-backdrop="true"
    :is-full-screen="false"
    :is-drag-handle="false"
  >
    <WhySubscriptionDialogContent
      class="mt-8"
      @update:is-open="
        (isOpenValue: boolean) =>
          emit('update:is-why-subscription-dialog-open', isOpenValue)
      "
    />
  </SwipeModal>
  <Modal
    v-if="mdAndLarger"
    :is-visible="isWhySubscriptionDialogOpen"
    :show-close-button="false"
    class="overflow-y-auto"
    @close="emit('update:is-why-subscription-dialog-open', false)"
  >
    <WhySubscriptionDialogContent
      @update:is-open="
        (isOpenValue: boolean) =>
          emit('update:is-why-subscription-dialog-open', isOpenValue)
      "
    />
  </Modal>
</template>

<script setup lang="ts">
import { SwipeModal } from '@takuma-ru/vue-swipe-modal';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { toggleBodyScroll } from '@/helpers/utils';

const props = defineProps({
  isWhySubscriptionDialogOpen: {
    type: Boolean,
    default: false,
  },
});

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndLarger = breakpoints.greaterOrEqual('md');

const emit = defineEmits(['update:is-why-subscription-dialog-open']);

const model = computed({
  get() {
    return props.isWhySubscriptionDialogOpen;
  },
  set(value) {
    emit('update:is-why-subscription-dialog-open', value);
  },
});

watch(
  mdAndLarger,
  (newValue, prevValue) => {
    if (newValue !== prevValue) {
      emit('update:is-why-subscription-dialog-open', false);
    }
  },
  { immediate: true }
);

watch(
  () => props.isWhySubscriptionDialogOpen,
  (visible) => {
    if (process.client) {
      toggleBodyScroll(!visible);
      if (visible && !mdAndLarger.value) {
        const dragHandle = document.getElementsByClassName(
          'swipe-modal-drag-handle-wrapper'
        )[0];
        if (dragHandle) {
          // Remove drag handle in swipe modal
          dragHandle.remove();
        }
      }
      const body = document.getElementsByTagName('body')[0];
      if (body && !mdAndLarger.value) {
        // The body is set to fixed to prevent scrolling on iPhones when the modal is open
        body.classList.toggle('fixed', visible);
        // When the position is fixed we need to set the width to 100% to prevent the body from flowing outside the viewport
        body.classList.toggle('w-full', visible);
      }
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  if (process.client) {
    toggleBodyScroll(true);
  }
});
</script>
