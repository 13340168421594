<template>
  <div>
    <SeoMeta
      :title="product?.pageTitle"
      :description="product?.metaDesc"
      :breadcrumbs="breadcrumbs"
      :image="headImage?.[0]?.node?.url"
      :disable-hreflang="true"
    />
    <ClearCacheMeta v-if="productFetchError" />
    <ProductDetail
      v-if="productIsFetchedAndAvailable"
      :product="product"
      :animal-types="product.animalCategories"
      :images="images"
      :options="options"
      :variants="variants"
      :primary-breadcrumb="primaryBreadcrumb"
      :is-subscribable="product.isSubscribable"
      :sanity-data="sanityData"
    />
    <ProductDetailSkeleton v-else />

    <ProductRelatedProducts
      v-if="
        productId &&
        productFetchStatus === 'success' &&
        !sanityData?.description
      "
      :product-id="productId"
    />
    <ProductComplementaryProducts
      v-if="
        productId &&
        productFetchStatus === 'success' &&
        !sanityData?.description
      "
      :product-id="productId"
    />
  </div>
</template>
<script setup lang="ts">
import { gql } from '@apollo/client/core';
import { imageAltTextIncludesSku } from '#root/shared/utils/product-utils';
import { GRAPHQL_OPERATION_NAME } from '#root/shared/config/graphql-query-config';

import {
  mapImagesData,
  mapOptionsData,
  mapVariantsData,
} from '@/helpers/product-data-mapper';
import { getPrimaryBreadcrumb } from '@/helpers/breadcrumbs';
import { productExtendedFragment } from '@/graphql/fragments';

import type { Product as GqlProduct } from '#root/shared/types/graphql-types';
import type { product as sanityProduct } from '#root/cms/sanity.types'; /* Sanity */

definePageMeta({
  layout: 'full-width',
});

defineI18nRoute({
  locales: ['sv', 'en'],
  paths: {
    sv: '/butik/produkt/[slug]',
    en: '/shop/product/[slug]',
  },
});

const route = useRoute();

/* Sanity Start */
const languageIsoCode = useIsoLanguageCode();
const query = groq`*[_type == "product" && language == $language && slug.current == $slug][0]{title, benefits, swiperImages, badge, description,  productPageBuilder[]{
      ...,
      _type == "productTestimonials" => {
        title,
        testimonials[]->{
          _id,
          name,
          image,
          jobTitle,
          testimonialTitle,
          testimonialContent,
           _updatedAt
        }
      }
    }}`;

const sanityData = ref<sanityProduct | null>(null);

if (hasSanity()) {
  const { data } = await useSanityQuery<sanityProduct>(query, {
    language: languageIsoCode,
    slug: route.params.slug,
  });
  sanityData.value = data.value;
}
/* Sanity End */

const { locale } = useI18n();
const localePath = useLocalePath();
const gtm = useGtm();

const shopName = computed(
  () => useShopCountryConfig().routes[useLanguage()].shop
);

const productByPathQuery = gql`
  query ${GRAPHQL_OPERATION_NAME.ProductByPath}($path: String!) {
    route(path: $path) {
      node {
        id
        ... on Product {
          ...ProductExtendedFields
        }
      }
    }
  }
  ${productExtendedFragment}
`;

const {
  data: productResult,
  status: productFetchStatus,
  error: productFetchError,
} = await useLazyAsyncQuery<{ route: { node: GqlProduct } }>({
  query: productByPathQuery,
  variables: {
    path: `/${route.params?.slug}/`,
  },
});

const product = computed(() => productResult?.value?.data?.route?.node);
const productId = computed(() => product.value?.entityId);
const productIsFetchedAndAvailable = computed(
  () =>
    productFetchStatus.value === 'success' &&
    !product.value?.isDeleted &&
    product.value?.isAvailable
);
const primaryBreadcrumb = computed(() => getPrimaryBreadcrumb(product.value));

const images = computed(() => mapImagesData(product.value));
const options = computed(() => mapOptionsData(product.value?.variants));
const variants = computed(() => mapVariantsData(product.value?.variants));

const hasSentProductDetailEvent = ref(false);

watch(
  productFetchStatus,
  async (productFetchStatusValue) => {
    if (
      productFetchStatusValue === 'idle' ||
      productFetchStatusValue === 'pending'
    ) {
      return;
    }

    // Show error page on error, redirect on unavailable/deleted or missing product
    if (productFetchError.value) {
      throw createError({
        statusCode: productFetchError.value.statusCode,
        statusMessage: productFetchError.value.statusMessage,
        fatal: true,
      });
    } else if (!productIsFetchedAndAvailable.value) {
      const primaryBreadcrumbPath =
        primaryBreadcrumb.value?.[primaryBreadcrumb.value.length - 1]?.path;
      const redirectPath = primaryBreadcrumbPath
        ? localePath(primaryBreadcrumbPath)
        : `/${locale.value}/${shopName.value}`;

      await navigateTo(redirectPath, { redirectCode: 301 });

      return;
    }

    if (process.client) {
      if (!hasSentProductDetailEvent.value) {
        // Track page view
        gtm?.trackEvent(productDetailEvent(product.value));
        hasSentProductDetailEvent.value = true;
      }
    }
  },
  { immediate: true }
);

const selectedOptions = formatQueryParams(route.query);
const selectedVariant = computed(
  () =>
    getSelectedVariant(selectedOptions, variants?.value) ||
    product.value?.defaultVariant
);

const productImages = computed(() => product.value?.images?.edges);

const variantImages = computed(() =>
  productImages.value?.filter((imageEdge: any) =>
    imageAltTextIncludesSku(
      imageEdge?.node?.altText,
      selectedVariant.value?.sku
    )
  )
);

const headImage = computed(() =>
  variantImages.value?.length ? variantImages.value : productImages.value
);

const breadcrumbs = computed(() => [
  ...primaryBreadcrumb.value,
  {
    name: product.value?.name,
    path: localePath({
      name: 'product-slug',
      params: { slug: route.params?.slug as string },
    }),
  },
]);

useJsonld(() => {
  const selectedVariantValue = selectedVariant.value;
  const productValue = product.value;

  return {
    '@context': 'https://schema.org',
    '@type': 'Product',
    productID: `${selectedVariantValue?.id ? selectedVariantValue.id : selectedVariantValue?.entityId}`, // NOTE: Used for Facebook product feed (use id if available)
    name: productValue?.name || '',
    image: productImages.value?.map((edge) => edge?.node?.url || '') || [],
    description: productValue?.metaDesc,
    brand: {
      '@type': 'Brand',
      name: productValue?.brand?.name,
    },
    offers: {
      '@type': 'Offer',
      url: route.path,
      priceCurrency: (selectedVariantValue || productValue)?.prices?.price
        ?.currencyCode,
      price: (selectedVariantValue || productValue)?.prices?.price?.value,
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
    },
  };
});
</script>
