<template>
  <div
    class="relative mx-auto flex size-full items-center"
    :class="cursor + ' ' + hasFixedDimensionsImageWrapper"
  >
    <img
      v-if="isRecommended && hasFixedDimensions"
      class="absolute right-2 top-2 z-10 size-11 md:size-12"
      :src="vetStamp"
    />

    <template v-if="image?.image?.asset?._ref">
      <NuxtImg
        :src="image?.image?.asset?._ref"
        :alt="image?.image?.alt || ''"
        fit="crop"
        crop="center"
        width="437"
        height="437"
        auto="format"
        class="hidden w-full md:block"
        provider="sanity"
      />

      <NuxtImg
        :src="image?.image?.asset?._ref"
        :alt="image?.image?.alt || ''"
        fit="crop"
        crop="center"
        width="389"
        height="241"
        auto="format"
        class="w-full md:hidden"
        provider="sanity"
      />
    </template>

    <OptimizedImage
      v-else
      class="mx-auto max-h-full mix-blend-darken"
      :class="hasFixedDimensionsImage"
      :src="image?.src || productImagePlaceholder"
      :alt="image?.alt || ''"
      :sizes="hasFixedDimensionsSizes"
      fetchpriority="high"
      :lazy="false"
      :modifiers="{ resource: 'product' }"
      :preload="true"
    />
  </div>
</template>

<script setup lang="ts">
import productImagePlaceholder from '@/assets/images/product-image-placeholder.png';
import vetStamp from '@/assets/images/vet-like-stamp.svg';

const hasFixedDimensionsImageWrapper = computed(() =>
  props.hasFixedDimensions ? 'aspect-[39/24] md:aspect-square' : 'h-[625px]'
);

const hasFixedDimensionsImage = computed(() =>
  props.hasFixedDimensions ? 'absolute object-contain  size-full' : ''
);

const hasFixedDimensionsSizes = computed(() => {
  if (props.hasFixedDimensions) {
    return 'md:225px lg:437px';
  }
  return 'md:800px lg:1000px ';
});

const props = defineProps({
  image: {
    type: Object,
    default: () => {},
  },
  isRecommended: {
    type: Boolean,
    default: false,
  },
  cursor: {
    type: String,
    default: 'cursor-default',
  },
  hasFixedDimensions: {
    type: Boolean,
    default: true,
  },
});
</script>
