<template>
  <div class="relative h-12 text-supporting-black-87">
    <div
      class="absolute left-0 top-0 w-full bg-white"
      :class="{
        'border-black-26 h-12 rounded border': !isOpen,
        'border-none': isOpen,
        'cursor-pointer': options.length > 1,
      }"
    >
      <div
        v-if="!isOpen"
        class="flex h-full flex-row justify-end"
        @click="
          if (options.length > 1) {
            isOpen = true;
          }
        "
      >
        <div
          class="my-auto ml-3 mr-auto text-supporting-black-87"
          data-test-id="selected-option-label"
        >
          {{ selectedOption.label }}
        </div>
        <div
          v-if="specialLabel && specialLabelCondition(selectedOption.value)"
          :class="`${specialLabelColor} my-auto mr-4`"
        >
          {{ specialLabel }}
        </div>
        <DynamicPictogram
          v-if="options.length > 1"
          name="caret"
          size="md"
          color="text-supporting-black-87"
          class="my-auto mr-3"
        />
      </div>
      <div
        v-else
        class="border-black-26 max-h-auto flex flex-col rounded border bg-white"
        :class="optionClass"
        data-test-id="select-options"
      >
        <div
          v-for="option in options"
          :key="`option-${option.value}`"
          :value="option.value"
          class="flex h-12"
          @click="selectOption(option)"
        >
          <span class="my-auto ml-3 mr-auto">{{ option.label }}</span>
          <span
            v-if="specialLabel && specialLabelCondition(option.value)"
            :class="`${specialLabelColor} my-auto mr-4`"
          >
            {{ specialLabel }}
          </span>
        </div>
      </div>
    </div>
    <select v-model="model" class="hidden">
      <option
        v-for="option in options"
        :key="`select-lite-option-${option.value}`"
        :value="option.value"
      ></option>
    </select>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  specialLabel: {
    type: String,
    default: '',
    required: false,
  },
  specialLabelColor: {
    type: String,
    default: 'text-supporting-black-87',
    required: false,
  },
  specialLabelCondition: {
    type: Function,
    default: () => Boolean,
  },
  optionClass: {
    type: String,
    default: '',
  },
  selectedIndex: {
    type: Number,
    default: 0,
  },
});

const model = defineModel<typeof props.options>();

const selectedOption = ref(
  props.options[props.selectedIndex] || props.options[0]
);

const isOpen = ref(false);

const selectOption = (option: any) => {
  selectedOption.value = option;
  model.value = option.value;

  isOpen.value = false;
};

onMounted(() => (model.value = selectedOption.value.value));
</script>
