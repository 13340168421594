<template>
  <div ref="details" class="details-tabs relative flex flex-wrap">
    <details
      v-for="(item, index) in list"
      :key="item._key"
      class="details-item group contents"
      name="topic"
      :open="openIndex?.includes(index)"
    >
      <summary
        class="details-tab md:order-0 relative flex w-full cursor-pointer items-center justify-between border-t px-6 py-5 font-semibold text-black/87 antialiased md:w-auto md:border-b-2 md:border-t-0 md:border-transparent md:px-4 md:py-3 md:font-normal md:group-open:pointer-events-none md:group-open:border-supporting-dark-3 md:group-open:font-semibold"
        @click.stop.prevent="toggleDetails(index)"
      >
        <slot name="summary" :item="item" />
        <DynamicPictogram
          name="chevron"
          class="-rotate-90 transition-transform group-open:rotate-90 md:hidden"
        />
      </summary>
      <div class="details-content w-full md:order-1 md:border-t">
        <slot name="detail" :item="item" />
      </div>
    </details>
  </div>
</template>

<script setup lang="ts">
import type { product as sanityProduct } from '#root/cms/sanity.types';

defineProps({
  list: {
    type: Array as () => sanityProduct['description'],
    required: true,
  },
});

const details = ref<HTMLElement | null>(null);
const openIndex = ref([0]);
const { isMd } = useTailwindBreakpoints();

function toggleDetails(index: number) {
  if (!details.value) return;
  if (isMd.value) {
    // Behavior for larger screens
    details.value.querySelectorAll('details').forEach((detail) => {
      if (!detail.hasAttribute('name')) {
        detail.setAttribute('name', 'topic');
      }
    });

    if (openIndex.value[0] !== index) {
      openIndex.value = [index];
      nextTick(() => {
        if (details.value) {
          details.value.querySelectorAll('details').forEach((detail) => {
            // eslint-disable-next-line no-param-reassign
            detail.open = false;
          });
          details.value.querySelectorAll('details')[index].open = true;
        }
      });
    } else {
      openIndex.value = [];
    }
  } else {
    // Behavior for mobile screens (multiple items open)
    details.value.querySelectorAll('details').forEach((detail) => {
      if (detail.hasAttribute('name')) {
        detail.removeAttribute('name');
      }
    });
    const currentIndex = openIndex.value.indexOf(index);
    if (currentIndex === -1) {
      openIndex.value.push(index);
    } else {
      openIndex.value.splice(currentIndex, 1);
    }
  }
}

watch(isMd, () => {
  openIndex.value = [0];
});

onMounted(() => {
  nextTick(() => {
    if (details.value) {
      const openItems = details.value.querySelectorAll('details[open]');
      if (openItems.length > 1) {
        details.value.querySelectorAll('details').forEach((detail) => {
          // eslint-disable-next-line no-param-reassign
          detail.open = false;
        });
        details.value.querySelectorAll('details')[0].open = true;
      }
    }
  });
});
</script>

<style scoped>
.details-tab::-webkit-details-marker {
  display: none;
}
</style>
