<template>
  <div
    v-if="product && product.name"
    class="product relative w-full bg-background-extra-light"
  >
    <div class="mx-auto max-w-6xl md:mt-0 md:px-6">
      <Breadcrumbs
        :breadcrumbs="[...primaryBreadcrumb, { name: product.name, path: '' }]"
        class="hidden py-6 antialiased md:block"
      />
      <div class="relative">
        <ProductCampaignBadge
          v-if="campaign"
          :type="campaign.type"
          :amount="campaign.amount"
          size="lg"
          :should-overflow="true"
          class="left-2 top-2 md:-left-4 md:-top-4"
          :color-class="
            blackWeekEnabledLocales.includes(locale)
              ? 'text-black/87'
              : 'bg-supporting-price'
          "
        />
      </div>
      <div class="mb-7 w-full md:mb-10 md:flex md:justify-between md:gap-12">
        <div
          class="relative mb-3 md:mb-0 md:mt-5 md:flex md:w-5/12 md:max-w-[437px] md:flex-col"
        >
          <img
            v-if="isFrozenFood"
            class="absolute right-2 top-2 z-10 size-11 md:size-12"
            :src="FrozenFood"
          />
          <div class="relative">
            <ProductDetailBadge :sanity-data="sanityData" />

            <ProductDetailImages
              :is-recommended="
                isVetRecommendedBadgeEnabled && !!product.isVetRecommended
              "
              :images="
                sanityData?.swiperImages ? sanityData?.swiperImages : allImages
              "
              :sku="sku"
            />
          </div>
        </div>
        <div class="px-4 md:flex md:w-7/12 md:flex-col md:px-0">
          <div class="mx-2 flex flex-wrap items-center md:mx-0 md:mb-3">
            <h2 class="font-sans text-inherit">
              <NuxtLink
                v-if="hasBrand"
                :to="
                  localePath({
                    name: 'shop-brands-slug',
                    params: { slug: product.brand?.slug },
                  })
                "
                class="hover:underline"
              >
                {{ product.brand?.name }}
              </NuxtLink>
            </h2>
            <span v-if="hasBrand" class="mx-2">/</span>
            <div
              v-for="(animalType, index) in props.animalTypes"
              :key="animalType.node?.entityId"
            >
              <NuxtLink
                :to="localePath(`/${shopName}${animalType.node?.path}`)"
                class="mr-1 hover:underline"
              >
                {{
                  animalType.node?.animal +
                  (index !== props.animalTypes.length - 1 ? ',' : '')
                }}
              </NuxtLink>
            </div>
          </div>

          <div class="mx-2 mb-3 md:mx-0">
            <h1 class="text-xl md:text-3xl" data-test-id="product-name">
              {{ product.name }}
            </h1>
          </div>
          <div class="hidden text-lg antialiased md:mb-3 md:block">
            {{ product.excerpt }}
          </div>

          <div class="mb-4 hidden md:block">
            <span class="text-black/87">{{ $t('product.sku') }}:</span>
            {{ sku }}
          </div>

          <div v-if="sanityData?.benefits" class="mb-3 hidden md:block">
            <div
              v-for="benefit in sanityData?.benefits"
              :key="benefit.id"
              class="mb-2 flex items-center gap-3 font-semibold text-black/87"
            >
              <DynamicPictogram name="check-mark" color="#110F0E8A" size="lg" />
              {{ benefit.text }}
            </div>
          </div>

          <div
            class="md:mb-auto md:flex-1"
            :class="{
              'md:pb-3': enabledFeaturesStore.isFeatureEnabled('stockLevel'),
              'md:pb-4': !enabledFeaturesStore.isFeatureEnabled('stockLevel'),
            }"
          ></div>

          <div class="mb-4 md:mb-1">
            <div
              v-if="singleVariantLabel || hasMultipleVariations"
              class="mx-1.5 flex flex-col md:mx-0"
            >
              <LabelGroup
                v-for="option in optionsWithPrices"
                :key="`label-group-${option.id}`"
                :model-value="data.selectedOptions[option.id]"
                :labels="option.values"
                title-key="label"
                value-key="id"
                class="md:mb-6"
                size="lg"
                :class="{
                  'hidden md:flex': hasMoreThanTwoVariations,
                  flex: hasMultipleVariations && !hasMoreThanTwoVariations,
                  flex: singleVariantLabel,
                }"
                @update:labels="(label) => setVariantRoute(option.id, label)"
              />
            </div>

            <div
              v-if="singleVariantLabel || hasMultipleVariations"
              class="flex flex-col"
            >
              <ClientOnly>
                <template v-if="hasMoreThanTwoVariations">
                  <Select
                    v-for="option in optionsWithPrices"
                    :key="`select-${option.id}`"
                    v-model="data.selectedOptions[option.id]"
                    :title="translateOptionDisplayName(option)"
                    :options="option.values"
                    :clearable="false"
                    :searchable="false"
                    :reduce="(option: MappedOption) => option.id"
                    :position-mobile-dropdown-to-bottom="true"
                    class="md:hidden"
                    @option:selected="
                      (selection) => setVariantRoute(option.id, selection.id)
                    "
                  >
                    <template #option="{ option: optionValue }">
                      <div class="flex justify-between py-2">
                        <div>
                          <span>{{ optionValue.label }}</span>
                        </div>
                        <div>
                          <span
                            v-if="optionValue.sale_price"
                            class="mr-2 font-semibold text-sale-price"
                          >
                            {{ $formatPrice(optionValue.sale_price) }}
                          </span>
                          <span
                            :class="{
                              'text-base-price line-through':
                                optionValue.sale_price,
                            }"
                          >
                            {{ $formatPrice(optionValue.base_price) }}
                          </span>
                        </div>
                      </div>
                    </template>
                  </Select>
                </template>
                <template v-if="hasMoreThanTwoVariations" #fallback>
                  <div class="select-placeholder flex md:hidden">
                    <div class="flex items-center justify-between py-2">
                      <div>
                        <span>{{
                          optionsWithPrices[0]?.values[0]?.label
                        }}</span>
                      </div>
                    </div>
                  </div>
                </template>
              </ClientOnly>
            </div>
          </div>
          <div class="flex flex-row flex-wrap justify-between md:gap-5">
            <div v-if="prices" class="mx-2 mb-4 md:mx-0">
              <div class="mb-1 flex items-baseline">
                <div class="font-title text-2xl md:text-3xl">
                  <span
                    class="mr-1"
                    :class="prices.salePrice ? 'text-sale-price' : 'text-price'"
                  >
                    {{ $formatPrice(prices.price.value) }}{{ ' ' }}
                  </span>
                  <span
                    v-if="prices.salePrice"
                    class="inline-block text-base-price line-through"
                  >
                    {{ $formatPrice(prices.basePrice.value) }}
                  </span>
                </div>
                <div v-if="totalSavingsFromBundle">
                  <span class="mx-2 text-black/26">/</span
                  ><span class="text-sale-price"
                    >{{ $t('product.save') }} -
                    {{ $formatPrice(totalSavingsFromBundle) }}
                  </span>
                </div>
              </div>
              <div v-if="priceOfFoodPerUnit">
                <span class="hidden" data-nosnippet
                  >{{ $t('product.comparison_price') }}:
                </span>
                {{ priceOfFoodPerUnit }} {{ $t('product.price_per_unit') }}
              </div>
              <div v-else-if="priceOfFoodPerLiter">
                <span class="hidden" data-nosnippet
                  >{{ $t('product.comparison_price') }}:
                </span>
                {{ priceOfFoodPerLiter }} {{ $t('product.price_per_liter') }}
              </div>
              <div v-else-if="priceOfFoodPerKilo">
                <span class="hidden" data-nosnippet
                  >{{ $t('product.comparison_price') }}:
                </span>
                {{ priceOfFoodPerKilo }} {{ $t('product.price_per_kilo') }}
              </div>
            </div>
            <FavoriteToggle
              v-if="locale === 'uk'"
              :product-id="product.entityId"
              :tracking-data="{
                name: product.name,
                price: prices.basePrice?.value,
              }"
              class="my-auto h-auto pb-4 pr-4 pt-0 lg:my-0 lg:h-12 lg:pt-2"
              :class="{
                'lg:order-last lg:ml-6': !isDiscountedBundledProduct,
              }"
            />
            <div
              class="ml-0 w-full space-y-3 lg:flex-1"
              :class="{
                'cursor-not-allowed': addToCartIsDisabled,
              }"
            >
              <Button
                v-if="!quantity || showAddedToCart"
                class="w-full min-w-40"
                data-test-id="add-to-cart-button"
                :disabled="addToCartIsDisabled"
                text-size="md"
                @click="!showAddedToCart && quantityChange(1)"
              >
                <transition name="fade" mode="out-in">
                  <span
                    v-if="showAddedToCart"
                    key="add-to-cart-loading"
                    class="flex items-center"
                  >
                    {{ $t('cart.added') }}
                    <DynamicPictogram name="check-mark" class="mr-2" />
                  </span>
                  <span v-else key="add-to-cart">
                    {{ addToCartText }}
                  </span>
                </transition>
              </Button>
              <QuantitySelector
                v-else
                class="w-full"
                :disable-increase="!isPurchasable"
                :qty="{ value: quantity }"
                :units-per-item="isFrozenFood ? units : 1"
                @input="quantityChange"
              />
              <ClientOnly v-if="productIsSubscribable">
                <template #fallback>
                  <ProductDetailSubscribeButton />
                </template>
                <ProductDetailSubscribeButton
                  @click="data.showSubscriptionModal = true"
                />
              </ClientOnly>

              <p v-if="productIsSubscribable" class="text-supporting-black-87">
                {{ $t('subscriptions.why_subscription_text') }}
                <a
                  class="cursor-pointer text-primary-default"
                  @click="
                    () => {
                      data.showWhySubscriptionModal = true;
                      data.whySubscriptionModalCloseAction = 'close';
                    }
                  "
                  >{{ $t('subscriptions.why_subscription_link') }}</a
                >
              </p>
            </div>
          </div>
          <ProductDetailInfo
            :is-frozen-food="isFrozenFood"
            :stock-count="stockCount"
            :in-stock="isPurchasable"
            :is-variant-selected="!!selectedVariant?.bundle"
            class="hidden md:flex"
          />
        </div>
      </div>

      <div v-if="sanityData?.benefits" class="mb-7 px-4 md:mb-3 md:hidden">
        <div
          v-for="benefit in sanityData?.benefits"
          :key="benefit.id"
          class="mb-2 flex items-center gap-3 text-black/87"
        >
          <DynamicPictogram name="check-mark" />
          {{ benefit.text }}
        </div>
      </div>

      <ProductDetailBrandPerks :sanity-data="sanityData" />

      <div class="px-4 md:hidden">
        <ProductDetailInfo
          :is-frozen-food="isFrozenFood"
          :stock-count="stockCount"
          :in-stock="isPurchasable"
          :is-variant-selected="!!selectedVariant?.bundle"
        />
      </div>
    </div>

    <section class="md:px-6">
      <div class="mx-auto max-w-6xl md:px-2">
        <ProductTabsSanity
          v-if="sanityData?.description"
          :sanity-data="sanityData"
        />

        <ProductTabsBigCommerce
          v-else
          :videos="product.videos"
          :description="product.description"
          :bundle="selectedVariant?.bundle"
          :lowest-price="
            isLowest30DaysPriceEnabled
              ? selectedVariant?.lowest30DaysPrice
              : null
          "
          class="mb-8"
        />
      </div>
    </section>

    <div class="my-4">
      <div
        v-if="sku && !selectedVariant?.bundle"
        class="mb-2 px-4 text-sm md:mx-0 md:hidden"
      >
        <span class="text-black/87">{{ $t('product.sku') }}:</span> {{ sku }}
      </div>

      <Breadcrumbs
        :breadcrumbs="[...primaryBreadcrumb, { name: product.name, path: '' }]"
        class="px-4 antialiased md:mx-0 md:hidden"
      />

      <template v-if="categories && categories.length">
        <template
          v-for="categoryEdge in categories"
          :key="categoryEdge?.node.entityId"
        >
          <Breadcrumbs
            class="hidden"
            :breadcrumbs="
              categoryEdge?.node?.breadcrumbs?.edges?.map((edge) => ({
                name: edge?.node?.name || '',
                path: edge?.node?.path || '',
              }))
            "
          />
        </template>
      </template>
    </div>

    <ProductPagebuilder :sanity-data="sanityData" />
    <SubscriptionSetupDialog
      :key="selectedVariant?.id + 1"
      :is-subscription-setup-dialog-open="data.showSubscriptionModal"
      :variants="variants"
      :product="product"
      :show-delivery-info="!isFrozenFood"
      :selected-variant="selectedVariant"
      @update:is-subscription-setup-dialog-open="
        (isOpen: boolean) => (data.showSubscriptionModal = isOpen)
      "
      @open-why-subscription="
        () => {
          data.showSubscriptionModal = false;
          data.showWhySubscriptionModal = true;
          data.whySubscriptionModalCloseAction = 'openSetupDialog';
        }
      "
      @added-subscription-product="
        (frequency: number) => {
          data.subscriptionFrequencyInDays = frequency;
          data.showSubscriptionModal = false;
          data.showAddedToCartModal = true;
        }
      "
    />
    <WhySubscriptionDialog
      :is-why-subscription-dialog-open="data.showWhySubscriptionModal"
      @update:is-why-subscription-dialog-open="
        (isWhySubscriptionDialogOpen: boolean) => {
          // The counter-intuitive order and the checking of data.showWhySubscriptionModal
          // Comes because the event is fired at initialization
          // If we let it trigger, it will force-open the SubscriptionSetupDialog
          if (
            !isWhySubscriptionDialogOpen &&
            data.showWhySubscriptionModal &&
            data.whySubscriptionModalCloseAction === 'openSetupDialog'
          ) {
            data.showSubscriptionModal = true;
          }
          data.showWhySubscriptionModal = isWhySubscriptionDialogOpen;
        }
      "
    />
    <AddedToCartDialog
      v-if="
        productIsSubscribable ||
        enabledFeaturesStore.isFeatureEnabled('addedToCart')
      "
      :is-added-to-cart-dialog-open="data.showAddedToCartModal"
      :product="product"
      :image="firstVariantImage"
      :subscription-frequency-in-days="data.subscriptionFrequencyInDays"
      @update:is-added-to-cart-dialog-open="
        (isOpen: boolean) => (data.showAddedToCartModal = isOpen)
      "
    />
    <DialogModal
      :is-visible="data.showConfirmReplaceSubscriptionWithSinglePurchaseModal"
      :title="
        $t('subscriptions.confirm_replace_with_single_purchase_modal.title')
      "
      :message="
        $t('subscriptions.confirm_replace_with_single_purchase_modal.message')
      "
      :confirm-button-text="
        $t('subscriptions.confirm_replace_with_single_purchase_modal.confirm')
      "
      :cancel-button-text="
        $t('subscriptions.confirm_replace_with_single_purchase_modal.cancel')
      "
      @confirm="removeSubscriptionAndAddToCart"
      @cancel="
        data.showConfirmReplaceSubscriptionWithSinglePurchaseModal = false
      "
    />
    <LazyBrazeAddToCartEvent v-if="shouldSendBrazeAddToCartEvent" />
  </div>
  <SubscriptionSetupDialog
    :is-subscription-setup-dialog-open="data.showSubscriptionModal"
    :variants="variants"
    :product="product"
    :show-delivery-info="!isFrozenFood"
    :selected-variant="selectedVariant"
    @update:is-subscription-setup-dialog-open="
      (isOpen: boolean) => (data.showSubscriptionModal = isOpen)
    "
    @open-why-subscription="
      () => {
        data.showSubscriptionModal = false;
        data.showWhySubscriptionModal = true;
        data.whySubscriptionModalCloseAction = 'openSetupDialog';
      }
    "
    @added-subscription-product="
      (frequency: number) => {
        data.subscriptionFrequencyInDays = frequency;
        data.showSubscriptionModal = false;
        data.showAddedToCartModal = true;
      }
    "
  />
  <WhySubscriptionDialog
    :is-why-subscription-dialog-open="data.showWhySubscriptionModal"
    @update:is-why-subscription-dialog-open="
      (isWhySubscriptionDialogOpen: boolean) => {
        // The counter-intuitive order and the checking of data.showWhySubscriptionModal
        // Comes because the event is fired at initialization
        // If we let it trigger, it will force-open the SubscriptionSetupDialog
        if (
          !isWhySubscriptionDialogOpen &&
          data.showWhySubscriptionModal &&
          data.whySubscriptionModalCloseAction === 'openSetupDialog'
        ) {
          data.showSubscriptionModal = true;
        }
        data.showWhySubscriptionModal = isWhySubscriptionDialogOpen;
      }
    "
  />
  <AddedToCartDialog
    v-if="
      productIsSubscribable ||
      enabledFeaturesStore.isFeatureEnabled('addedToCart')
    "
    :is-added-to-cart-dialog-open="data.showAddedToCartModal"
    :product="product"
    :images="allImages"
    :subscription-frequency-in-days="data.subscriptionFrequencyInDays"
    @update:is-added-to-cart-dialog-open="
      (isOpen: boolean) => (data.showAddedToCartModal = isOpen)
    "
  />
  <LazyBrazeAddToCartEvent v-if="shouldSendBrazeAddToCartEvent" />
</template>

<script setup lang="ts">
import {
  formatQueryParams,
  getSelectedVariant,
  isVariantSelected,
} from '@/utils/product-utils';
import { productTrackingData } from '@/utils/tracking-utils';
import { useSearchStore } from '~/stores/search';
import { useCartStore } from '~/stores/cart';
import { useCheckoutStore } from '~/stores/checkout';
import { useEnabledFeaturesStore } from '~/stores/enabledFeatures';
import type {
  Product as GqlProduct,
  Variant as GqlVariant,
  ProductPrices as GqlProductPrices,
  CartItemInput,
} from '#root/shared/types/graphql-types';
import { ProductStorageClimate } from '#root/shared/types/graphql-types';
import FrozenFood from '~/assets/images/frozen-food.svg?url';
import type { LocationQuery } from '#vue-router';
import type { product as sanityProduct } from '#root/cms/sanity.types'; /* Sanity */
import { imageAltTextIncludesSku } from '#root/shared/utils/product-utils';

const {
  blackWeekEnabledLocales,
  isLowest30DaysPriceEnabled,
  isVetRecommendedBadgeEnabled,
} = useRuntimeConfig().public.featureFlags;

const { t, te, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const localePath = useLocalePath();
const gtm = useGtm();
const cartStore = useCartStore();
const checkoutStore = useCheckoutStore();
const searchStore = useSearchStore();
const enabledFeaturesStore = useEnabledFeaturesStore();
const subscriptionStore = useSubscriptionStore();

const shouldSendBrazeAddToCartEvent = computed(
  () =>
    cartStore.shouldSendBrazeAddToCartEventForProductId ===
    props.product.entityId
);

const shopName = computed(
  () => useShopCountryConfig().routes[useLanguage()].shop
);

// The options and variants are mapped in product-data-mapper.js on client side. We should remove this extra layer of complexity and just use the GqlProduct directly.
// Jira ticket: https://firstvet.atlassian.net/browse/ECOM-180
interface MappedOption {
  id: number;
  display_name: string;
  values: [
    {
      id: number;
      label: string;
      base_price: number;
      sale_price: number;
    },
  ];
}

interface MappedVariant {
  id: GqlVariant['entityId'];
  sku: GqlVariant['sku'];
  prices: GqlVariant['prices'];
  inventory: GqlVariant['inventory'];
  is_purchasable: GqlVariant['isPurchasable'];
  lowest30DaysPrice: GqlVariant['lowest30DaysPrice'];
  option_values: {
    id: number;
    display_name: string;
    label: string;
    option_id: number;
  }[];
  campaign: GqlVariant['campaign'];
  bundle: GqlVariant['bundle'];
  units: GqlVariant['units'];
}

interface Props {
  product: GqlProduct;
  animalTypes: GqlProduct['animalCategories'][];
  images: { id: string; src: string; alt: string }[];
  options: MappedOption[];
  variants: MappedVariant[];
  primaryBreadcrumb: { name: string; path: string }[];
  isSubscribable: GqlProduct['isSubscribable'];
  sanityData: sanityProduct /* Sanity */;
}

const props = withDefaults(defineProps<Props>(), {
  product: () => ({}) as GqlProduct,
  animalTypes: () => [],
  images: () => [],
  options: () => [],
  variants: () => [],
  primaryBreadcrumb: () => [],
  sanityData: {} as sanityProduct /* Sanity */,
});

const data = reactive({
  addToCartLoading: false,
  addToCartClicked: false,
  selectedOptions: {} as { [optionId: number]: number },
  postalCode: '',
  cart: computed(() => cartStore.cart),
  statePostalCode: computed(() => checkoutStore.postalCode),
  showSubscriptionModal: false,
  showAddedToCartModal: false,
  showWhySubscriptionModal: false,
  showConfirmReplaceSubscriptionWithSinglePurchaseModal: false,
  whySubscriptionModalCloseAction: 'close',
  subscriptionFrequencyInDays: 0,
});

const { subscriptionItemCount } = storeToRefs(subscriptionStore);

const validateQueryParams = (query: LocationQuery) => {
  const querySelectedOptions = formatQueryParams(query);

  const allAvailableOptionValues = props.variants.reduce(
    (acc: { [optionId: string]: number }[][], variant) => [
      ...acc,
      variant.option_values.map((optionValue) => ({
        [`${optionValue.option_id}`]: optionValue.id,
      })),
    ],
    []
  );

  const querySelectedOptionEntries = Object.entries(querySelectedOptions);

  return querySelectedOptionEntries.length
    ? allAvailableOptionValues.some((availableOptionValues) =>
        querySelectedOptionEntries.every(([optionId, optionValueId]) =>
          availableOptionValues.some(
            (optionValue) => optionValue[optionId] === optionValueId
          )
        )
      )
    : false;
};

const getDefaultVariantOptions = (): { [optionId: number]: number } => {
  // Compare product head's default prices to variant prices to see which variants to preselect
  const { basePrice: productBasePrice, salePrice: productSalePrice } = props
    .product.prices as GqlProductPrices;

  return props.variants.reduce((acc, variant) => {
    const { basePrice: variantBasePrice, salePrice: variantSalePrice } =
      variant?.prices || {};

    // If prices are matching and no variant is selected yet (should take first available match),
    // preselect this variant
    if (
      !Object.keys(acc).length &&
      variantBasePrice?.value === productBasePrice?.value &&
      variantSalePrice?.value === productSalePrice?.value
    ) {
      return variant.option_values.reduce(
        (accOptions, optionValue) => ({
          ...accOptions,
          [optionValue.option_id]: optionValue.id,
        }),
        {}
      );
    }

    return acc;
  }, {});
};

const getInitiallySelectedOptions = (query: LocationQuery) => {
  const defaultSelectedOptions = getDefaultVariantOptions();
  const querySelectedOptions = formatQueryParams(query);

  return {
    ...defaultSelectedOptions,
    ...querySelectedOptions,
  };
};

const hasBrand = computed(
  () => props.product?.brand?.slug && props.product?.brand?.name
);

const itemInCart = computed(() =>
  props.product?.entityId && selectedVariant.value?.id
    ? cartStore.getItemInCart(props.product.entityId, selectedVariant.value?.id)
    : null
);
const quantity = computed(() => itemInCart.value?.quantity || 0);
const prices = computed(() => {
  if (selectedVariant.value) {
    return selectedVariant.value.prices;
  }

  return props.product?.prices;
});

const units = computed(() => {
  if (selectedVariant.value) {
    return selectedVariant.value.units;
  }

  return null;
});

const isFood = computed(
  () =>
    props.product?.categories?.edges?.some((categoryEdge) =>
      /foder\b|pellets|froer/.test(categoryEdge?.node?.path || '')
    ) || false
);

const isFrozenFood = computed(
  () =>
    isFood.value &&
    props.product?.storageClimate === ProductStorageClimate.Frozen
);

const priceOfFoodPerKilo = computed(() => prices.value?.pricePerKilo?.value);
const priceOfFoodPerLiter = computed(() => prices.value?.pricePerLiter?.value);
const priceOfFoodPerUnit = computed(() => prices.value?.pricePerUnit?.value);

const campaign = computed(() =>
  selectedVariant.value
    ? selectedVariant.value.campaign
    : props.product?.campaign
);

const hasMultipleVariations = computed(
  () => props.variants?.length && props.variants.length > 1
);

const hasMoreThanTwoVariations = computed(
  () => props.variants?.length && props.variants.length > 2
);

const selectedVariant = computed(() =>
  getSelectedVariant(data.selectedOptions, props.variants)
);

const inventory = computed(() => selectedVariant?.value?.inventory);

const stockCount = computed(
  () => inventory?.value?.aggregated?.availableToSell
);

const isPurchasable = computed(
  () => !selectedVariant?.value || selectedVariant?.value?.is_purchasable
);

const optionsWithPrices = computed(() =>
  // Update prices depending on the selected options
  props.options.map((option) => {
    const selectedOptionsExceptThis = {
      ...data.selectedOptions,
    };
    delete selectedOptionsExceptThis[option.id];

    return {
      ...option,
      values: option.values.map((optionValue) =>
        // Find the variant with the matching options by using the currently selected option (ex. has selected 'Red'),
        // and the other possible selections (ex. 'Small', 'Medium', 'Large').
        // I.e. variants for combination 'Red-Small', 'Red-Medium', 'Red-Large'.
        // Update prices for this option value according to the matched variant.

        props.variants.reduce((acc, variant) => {
          const matchesOtherSelectedOptions = isVariantSelected(
            selectedOptionsExceptThis,
            variant
          );

          if (matchesOtherSelectedOptions) {
            const optionValues = variant.option_values.reduce(
              (accu, variantOptionValue) => {
                if (
                  variantOptionValue.option_id === option.id &&
                  variantOptionValue.id === optionValue.id
                ) {
                  return {
                    ...optionValue,
                    sale_price: variant?.prices?.salePrice?.value,
                    base_price: variant?.prices?.basePrice?.value,
                    is_purchasable: variant?.is_purchasable,
                    stock_count:
                      variant?.inventory?.aggregated?.availableToSell,
                  };
                }

                return accu;
              },
              {}
            );

            if (Object.keys(optionValues).length) {
              return optionValues;
            }
          }

          return acc;
        }, {})
      ),
    };
  })
);

const singleVariantLabel = computed(() => {
  const variantLabel = selectedVariant.value?.option_values?.[0]?.label;
  const productName = props.product?.name;

  return !hasMultipleVariations.value &&
    variantLabel &&
    productName &&
    variantLabel.toLowerCase() !== productName.toLowerCase()
    ? variantLabel
    : '';
});
const sku = computed(() => {
  if (!props.product) return '';

  if (selectedVariant.value) {
    return selectedVariant.value.sku;
  }

  return props.product?.sku || '';
});

const bundledImages: ComputedRef<{
  [sku: string]: { id: string; src: string; alt: string }[];
}> = computed(() => {
  if (!props.product?.variants?.edges) {
    return {};
  }

  return props.product.variants.edges.reduce((acc, edge) => {
    const bundleItems = edge?.node?.bundle?.items;
    if (!bundleItems) {
      return acc;
    }

    return {
      ...acc,
      [edge.node.sku]: bundleItems.map((bundleItem) => ({
        id: bundleItem?.sku,
        src: bundleItem?.defaultImageUrl,
        alt: bundleItem?.name,
      })),
    };
  }, {});
});
const selectedBundleImages = computed(() => {
  if (!bundledImages.value) {
    return [];
  }

  return bundledImages.value?.[selectedVariant.value?.sku] || [];
});
const allImages = computed(() => [
  ...props.images,
  ...selectedBundleImages.value,
]);

const productImages = computed(() => props.product?.images?.edges);

const variantImages = computed(
  () =>
    productImages.value?.filter((imageEdge: any) =>
      imageAltTextIncludesSku(
        imageEdge?.node?.altText,
        selectedVariant.value?.sku
      )
    ) || []
);

const firstVariantImage = computed(() => {
  const image = variantImages.value?.[0]?.node;
  if (image && image.url) {
    return { src: image.url, alt: image.altText || '' }; // Return valid image object
  }
  return allImages.value[0];
});

const totalSavingsFromBundle = computed(() => {
  if (!selectedVariant.value?.bundle?.totalSavings) {
    return null;
  }

  return selectedVariant.value.bundle.totalSavings.value;
});
const isDiscountedBundledProduct = computed(
  () => totalSavingsFromBundle.value && prices.value?.salePrice
);
// Add to cart button
const addToCartIsDisabled = computed(() => !isPurchasable.value);
const addToCartText = computed(() => {
  if (!isPurchasable.value) {
    return t('product.out_of_stock');
  }

  if (isFrozenFood.value && units.value > 1) {
    return t('product.add_to_cart_multiple', {
      units: units.value,
    });
  }

  return t('product.add_to_cart');
});
const showAddedToCart = computed(
  () => data.addToCartLoading || data.addToCartClicked
);
const categories = computed(() => {
  const currentCategories = useShopCountryConfig()?.categories?.[locale.value];

  return props.product?.categories?.edges?.filter(
    (category) =>
      category?.node?.path?.includes(currentCategories.animalType) ||
      category?.node?.path?.includes(currentCategories.areas)
  );
});

const productIsSubscribable = computed(
  () =>
    enabledFeaturesStore.isFeatureEnabled('subscriptions') &&
    props.product?.isSubscribable &&
    !addToCartIsDisabled.value
);

const cartItemAlreadyAddedAsSubscription = computed(() =>
  subscriptionStore.getSubscriptionProductInCart(
    props.product.entityId,
    selectedVariant.value?.id
  )
);

onMounted(() => {
  data.postalCode = data.statePostalCode;
});

const translateOptionDisplayName = (option: { display_name: string }) => {
  const translationKey = `product.options.${option?.display_name?.toLowerCase()}`;

  return te(translationKey) ? t(translationKey) : option.display_name;
};

const setVariantRoute = (optionId: number, label: number) => {
  data.selectedOptions[optionId] = label;
  const variantRoute = {
    path: route.path,
    query: {
      options: Object.keys(data.selectedOptions).join(','),
      values: Object.values(data.selectedOptions).join(','),
    },
  };

  const parsedRoute = router.resolve(variantRoute);

  // Avoid redundant navigation
  if (parsedRoute?.fullPath !== route.fullPath) {
    router.replace(variantRoute);
  }
};

const addToCart = (addToCartPayload: {
  cartItems: {
    item: CartItemInput;
    meta: {
      isOnSale: boolean;
      categoryIds: GqlProduct['categoryIds'];
      brand?: GqlProduct['brand'];
    };
  }[];
}) => {
  cartStore.addToCart(addToCartPayload);
  data.subscriptionFrequencyInDays = 0;

  if (!data.cart && subscriptionItemCount.value === 0) {
    data.showAddedToCartModal = true;
  }
};

const deleteCartItem = () => cartStore.deleteCartItem(itemInCart.value.id);
const updateCartItem = (cartItem: {
  itemId: number;
  cartItem: {
    item: CartItemInput;
    meta: {
      isOnSale: boolean;
      categoryIds: GqlProduct['categoryIds'];
      brand?: GqlProduct['brand'];
    };
  };
}) => cartStore.updateCartItem(cartItem);
const sendLoop54AddToCartEvent = (entityId: number) =>
  searchStore.sendLoop54AddToCartEvent(entityId);

enum TrackCartEventAction {
  Add = 'add',
  Remove = 'remove',
}

const trackCartUpdate = (
  action: TrackCartEventAction,
  currentQuantity: number
) => {
  const products = [
    {
      ...productTrackingData({
        ...props.product,
        prices: selectedVariant.value?.prices,
        variantId: selectedVariant.value?.id,
      }),
      quantity: currentQuantity,
    },
  ];

  if (action === TrackCartEventAction.Add) {
    sendLoop54AddToCartEvent(props.product.entityId);
    gtm?.trackEvent(addToCartEvent(products));
  } else {
    gtm?.trackEvent(removeFromCartEvent(products));
  }
};

const quantityChange = async (input: number) => {
  const newQuantity = input < 0 ? 0 : input;
  if (itemInCart.value) {
    if (newQuantity === 0) {
      trackCartUpdate(TrackCartEventAction.Remove, itemInCart.value.quantity);
      deleteCartItem();
    } else {
      updateCartItem({
        itemId: itemInCart.value.id,
        cartItem: {
          item: {
            quantity: newQuantity,
            productId: itemInCart.value.productId,
            variantId: itemInCart.value.variantId,
          },
          meta: {
            isOnSale: !!campaign.value?.amount,
            categoryIds: props.product?.categoryIds,
            brand: props.product?.brand,
          },
        },
      });
    }
  } else if (newQuantity === 1) {
    if (cartItemAlreadyAddedAsSubscription.value) {
      data.showConfirmReplaceSubscriptionWithSinglePurchaseModal = true;
      return;
    }

    data.addToCartClicked = true;
    data.addToCartLoading = true;

    // Make sure 'added to cart' text is visible for at least 1400 ms in case loading time is very short
    setTimeout(() => {
      data.addToCartClicked = false;
    }, 1400);

    await addToCart({
      cartItems: [
        {
          item: {
            quantity: newQuantity,
            productId: props.product.entityId,
            variantId: selectedVariant.value?.id,
          },
          meta: {
            isOnSale: !!campaign.value?.amount,
            categoryIds: props.product?.categoryIds,
            brand: props.product?.brand,
          },
        },
      ],
    });
    data.addToCartLoading = false;

    trackCartUpdate(TrackCartEventAction.Add, newQuantity);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

const removeSubscriptionAndAddToCart = () => {
  if (cartItemAlreadyAddedAsSubscription.value) {
    subscriptionStore.removeSubscriptionProduct(
      cartItemAlreadyAddedAsSubscription.value.productId,
      cartItemAlreadyAddedAsSubscription.value.variantId,
      cartItemAlreadyAddedAsSubscription.value.frequencyInDays
    );
  }

  quantityChange(1);

  data.showConfirmReplaceSubscriptionWithSinglePurchaseModal = false;
};

// If the query params in the url contains invalid variant option ids, remove them and use the default options instead
// Make sure to remove the enabledFeatures query param as well, as we do not want to redirect if the user has enabled features
const queryWithoutEnabledFeatures = { ...route.query };
delete queryWithoutEnabledFeatures.enabledFeatures;

if (
  Object.keys(queryWithoutEnabledFeatures).length &&
  !validateQueryParams(queryWithoutEnabledFeatures)
) {
  await navigateTo({
    ...route,
    query: {},
  });
}

// set initially selected options
data.selectedOptions = getInitiallySelectedOptions(route.query);
</script>

<style lang="scss" scoped>
// This style section is needs to be scoped for the overwriting to work properly

:deep(.modal-style) {
  background-color: #fefaf4 !important;
}
.select-placeholder {
  background: var(--input-bg-color);
  border-radius: var(--vs-border-radius);
  padding: 8px 15px;
  border: 1px solid var(--vs-border-color);
  color: rgb(51, 51, 51);
  --vs-border-radius: 4px;
  --input-bg-color: white;
  --input-placeholder-color: rgba(black, 0.87);
  height: 50.4px;
}
</style>
