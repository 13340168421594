<template>
  <!-- The SwipeModal library doesn't work well on iPhones.
    1. The drag feature is laggy in our app which makes it feels broken
    2. The background is scrolled when dragging the modal
    Workaround: Remove the swipe feature and add a close button
  -->
  <SwipeModal
    v-if="!mdAndLarger"
    v-model="model"
    snap-point="93dvh"
    :is-scroll-lock="true"
    :is-backdrop="true"
    :is-full-screen="false"
    :is-drag-handle="false"
  >
    <div
      class="absolute z-60 mt-8 flex w-full justify-center pb-2"
      :class="{
        'shadow-md': modalScrolled,
      }"
    >
      <h2 class="text-center text-2xl text-supporting-black-87">
        {{
          $t('subscriptions.subscribe_and_save', {
            percentage: subscriptionDiscountPercentage,
          })
        }}
      </h2>
      <Button
        class="absolute right-2"
        size="sm"
        color="supporting-black-54-no-border"
        @click="model = false"
      >
        <DynamicPictogram name="times" />
      </Button>
    </div>
    <SubscriptionSetupDialogContent
      v-if="variants.length > 0"
      class="mt-[76px]"
      :variants="variants"
      :product="product"
      :show-delivery-info="showDeliveryInfo"
      :selected-variant-id="selectedVariant?.id"
      @scroll="handleScroll"
      @open-why-subscription="emit('open-why-subscription')"
      @added-subscription-product="
        (frequencyInDays: number) =>
          emit('added-subscription-product', frequencyInDays)
      "
    />
  </SwipeModal>
  <Modal
    v-if="mdAndLarger"
    :is-visible="isSubscriptionSetupDialogOpen"
    class="overflow-y-auto"
    :show-close-button="false"
    @close="emit('update:is-subscription-setup-dialog-open', false)"
  >
    <SubscriptionSetupDialogContent
      v-if="variants.length > 0"
      :variants="variants"
      :product="product"
      :show-delivery-info="showDeliveryInfo"
      :selected-variant-id="selectedVariant?.id"
      @open-why-subscription="emit('open-why-subscription')"
      @added-subscription-product="
        (frequencyInDays: number) =>
          emit('added-subscription-product', frequencyInDays)
      "
    />
  </Modal>
</template>

<script setup lang="ts">
import { SwipeModal } from '@takuma-ru/vue-swipe-modal';
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';
import { toggleBodyScroll } from '@/helpers/utils';

const subscriptionStore = useSubscriptionStore();

const { subscriptionDiscountPercentage } = subscriptionStore;

const props = defineProps({
  isSubscriptionSetupDialogOpen: {
    type: Boolean,
    default: false,
  },
  variants: {
    type: Array,
    default: () => [],
  },
  product: {
    type: Object,
    default: () => {},
  },
  showDeliveryInfo: {
    type: Boolean,
    default: false,
  },
  selectedVariant: {
    type: Object,
    default: () => {},
  },
});

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndLarger = breakpoints.greaterOrEqual('md');

const windowScrollPosition = ref(0);
const modalScrolled = ref(false);

const emit = defineEmits([
  'update:is-subscription-setup-dialog-open',
  'open-why-subscription',
  'added-subscription-product',
]);

const model = computed({
  get() {
    return props.isSubscriptionSetupDialogOpen;
  },
  set(value) {
    emit('update:is-subscription-setup-dialog-open', value);
  },
});

const handleScroll = (el) => {
  modalScrolled.value = el.target.scrollTop > 0;
};

watch(
  mdAndLarger,
  (newValue, prevValue) => {
    if (newValue !== prevValue) {
      emit('update:is-subscription-setup-dialog-open', false);
    }
  },
  { immediate: true }
);

watch(
  () => props.isSubscriptionSetupDialogOpen,
  (visible) => {
    if (process.client) {
      toggleBodyScroll(!visible);
      if (visible && !mdAndLarger.value) {
        const dragHandle = document.getElementsByClassName(
          'swipe-modal-drag-handle-wrapper'
        )[0];
        if (dragHandle) {
          // Remove drag handle in swipe modal
          dragHandle.remove();
        }
        if (window && window.scrollY > 0) {
          // The scroll position is reset when the body position is set to fixed
          windowScrollPosition.value = window.scrollY;
        }
      }
      const body = document.getElementsByTagName('body')[0];
      if (body && !mdAndLarger.value) {
        // The body is set to fixed to prevent scrolling on iPhones when the modal is open
        body.classList.toggle('fixed', visible);
        // When the position is fixed we need to set the width to 100% to prevent the body from flowing outside the viewport
        body.classList.toggle('w-full', visible);
      }
      if (
        !visible &&
        window &&
        windowScrollPosition.value > 0 &&
        !mdAndLarger.value
      ) {
        window.scrollTo(0, windowScrollPosition.value);
      }
    }
  },
  { immediate: true }
);

onUnmounted(() => {
  if (process.client) {
    toggleBodyScroll(true);
  }
});
</script>
