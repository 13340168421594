<template>
  <div class="relative">
    <div
      ref="scrollable"
      class="scrollable flex cursor-grab select-none snap-x snap-mandatory list-none overflow-x-auto whitespace-nowrap mix-blend-darken"
    >
      <div
        v-for="image in images"
        :key="image?.id"
        class="w-full shrink-0 snap-start overflow-hidden"
      >
        <ProductDetailImage
          :image="image"
          :is-recommended="isRecommended"
          cursor="cursor-pointer"
          :has-fixed-dimensions="hasFixedDimensions"
          @click="toggleModal"
        />
      </div>
    </div>

    <div
      class="flex flex-row items-center justify-center gap-2"
      :class="{ 'opacity-0': !isInitialized }"
    >
      <SwiperPrev :has-prev="hasPrev" :go-to-prev="goToPrev" />
      <SwiperNext :has-next="hasNext" :go-to-next="goToNext" />
    </div>
  </div>

  <div v-if="showThumbnails" class="mt-3 hidden grid-cols-5 gap-2 md:grid">
    <button
      v-for="(image, index) in thumbnailImages"
      :key="image?.id"
      :aria-label="`Go to slide ${index + 1}`"
      @click="goTo(index + 1)"
    >
      <NuxtImg
        v-if="image?.image?.asset?._ref"
        :src="image?.image?.asset?._ref"
        :alt="image?.image?.alt"
        fit="crop"
        crop="center"
        width="80"
        height="80"
        auto="format"
        provider="sanity"
        class="transition-border size-20 cursor-pointer rounded border object-contain mix-blend-darken duration-300 ease-in"
        :class="
          groups?.[index]?.isVisible
            ? 'border-black/54'
            : 'border-transparent hover:border-black/26 focus:border-black/54'
        "
      />

      <OptimizedImage
        v-else
        :src="image?.src"
        :alt="image?.alt"
        class="transition-border size-20 cursor-pointer rounded border object-contain mix-blend-darken duration-300 ease-in"
        :class="
          groups?.[index]?.isVisible
            ? 'border-black/54'
            : 'border-transparent hover:border-black/26 focus:border-black/54'
        "
        width="80"
        height="80"
        sizes="xs:80px"
        :lazy="false"
      />
    </button>
  </div>
  <div
    class="flex flex-row items-center justify-center pb-1 pt-2 md:pb-0 md:pt-8"
    :class="{ 'md:hidden': showThumbnails }"
  >
    <button
      v-for="(image, index) in images"
      :key="image?.id"
      class="transition-opacity duration-300 ease-in"
      :class="{
        'opacity-100': groups?.[index]?.isTotallyVisible,
        'opacity-15': !groups?.[index]?.isVisible,
      }"
      @click="goTo(index + 1)"
    >
      <span class="m-1 block size-2 rounded bg-black/54"></span>
    </button>
  </div>
</template>
<script setup lang="ts">
import useSwiper from '../../ui/interactive/Swiper/useSwiper';

interface Props {
  images: { id: string; src: string; alt: string }[];
  sku: string;
  isRecommended: boolean;
  showThumbnails?: boolean;
  hasFixedDimensions?: boolean;
  activeGroup: number;
  gotoActiveGroup: number;
}

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
  sku: '',
  isRecommended: false,
  showThumbnails: true,
  hasFixedDimensions: true,
  activeGroup: 1,
  gotoActiveGroup: 1,
});

const scrollable = ref<HTMLElement | null>(null);
const thumbnailImages = computed(() => props?.images?.slice(0, 5));

const emit = defineEmits(['toggleModal', 'groupChanged']);

const toggleModal = () => {
  emit('toggleModal');
};

const isInitialized = ref(false);

onMounted(() => {
  isInitialized.value = true;
});

const {
  groups,
  goTo,
  goToNext,
  goToPrev,
  hasNext,
  hasPrev,
  lastKnownVisibleGroupMin,
} = useSwiper(scrollable);

onMounted(() => {
  if (props.activeGroup && props.activeGroup !== 0) {
    goTo(props.activeGroup, false);
  } else if (props.gotoActiveGroup) {
    goTo(props.gotoActiveGroup, false);
  }
});

watch(
  () => props.gotoActiveGroup,
  (newGotoActiveGroup) => {
    nextTick(() => {
      goTo(newGotoActiveGroup, false);
    });
  },
  { immediate: false }
);

watch(lastKnownVisibleGroupMin, (newActiveGroup) => {
  emit('groupChanged', newActiveGroup);
});
</script>

<style scoped>
.scrollable::-webkit-scrollbar {
  display: none;
}

.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
}
</style>
