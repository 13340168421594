export function hasSanity() {
  // Uses SANITY_ALLOWED_SLUGS from runtime config to check if the current slug is allowed, this is a temporary until proxy is implemented
  const route = useRoute();
  const { slug } = route.params;

  const { sanityAllowedSlugs: sanityAllowedSlugsString } =
    useRuntimeConfig().public;

  const sanityAllowedSlugs = sanityAllowedSlugsString.split(',');

  return sanityAllowedSlugs.includes(slug);
}
